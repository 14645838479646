// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "../stylesheets/application";

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


$(document).on("click", ".open-deleteadminModal", function() {
  var admin_id = $(this).data("admin_id");
  $(".modal-body #id").val(admin_id);
});
$(document).on("click", ".open-editadminModal", function() {
  var admin_id = $(this).data("admin_id");
  $(".modal-body #id").val(admin_id);
  $.ajax({
    url: "/admins/" + admin_id + "",
    type: "GET",
    success: (data) => {
      $(".modal-body #admin_email").val(data["email"]);
      $(".modal-body #admin_first_name").val(data["first_name"]);
      $(".modal-body #admin_last_name").val(data["last_name"]);
      $(".modal-body #admin_role").val(data["role"]);
    },
  });
});